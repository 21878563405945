<template>
  <div class="department-select">
    <div :class="disabled ? 'trigger disabled' : 'trigger'" v-if="showTrigger" @click.stop="handleTrigger">
      <template v-if="state.selectList.length">
        <slot name="label" :list="state.selectList">
          <el-tooltip :content="viewText" placement="top" effect="light">
            <div class="view-text ellipsis">
              <template v-for="(item, index) in state.selectList" :key="item.id">
                <span type="departmentName" :openid="item.name">{{ item.name }}</span>
                <span v-if="index !== state.selectList.length - 1">、</span>
              </template>
            </div>
          </el-tooltip>
        </slot>
      </template>
      <div v-else class="placeholder">{{ placeholder }}</div>
      <!-- <div class="select-item" v-for="department in state.selectList" :key="department.id">{{ department.name }}</div> -->
    </div>
    <OrganizationSelect
      :filter="true"
      :config="state.config"
      @cancel="cancelOrgan"
      @confirm="confirmOrgan"
      :select_data="state.selectList"
      :single="true"
    />
    <!-- <transfer-dialog
      ref="transferDialog"
      title="选择部门"
      selectType="department"
      children-key="childList"
      v-model:visible="state.visible"
      :root-name="state.rootName"
      :root-id="state.rootId"
      :selecteds="state.selectList"
      :topDepartment="state.topDepartment"
      :single="single"
      :nextFn="nextFn"
      @confirm="confirm"
      @cancel="cancel"
      :search="onSearch"
    ></transfer-dialog> -->
  </div>
</template>
<script>
import { computed, onUnmounted, reactive, watch, ref } from 'vue'
// import { useStore } from 'vuex'
import { getDepartmentList, getLazyTreeDepartment } from '@/apis'
// import TransferDialog from '@/components/transfer-dialog/index.vue'
import OrganizationSelect from '@/components/organization-select/index.vue'
export default {
  name: 'department-select',
  components: { OrganizationSelect },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    single: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '选择部门'
    },
    showTrigger: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'ok', 'confirm', 'change'],
  setup(props, { emit }) {
    const transferDialog = ref(null)
    // const store = useStore()
    const updateValue = value => {
      emit('update:modelValue', value) // 传递的方法
    }
    const state = reactive({
      list: [],
      visible: false,
      selectList: [],
      rootName: '',
      rootId: '',
      rootData: [],
      topDepartment: {}, //顶级部门
      config: {
        show: false
      }
    })
    const viewText = computed(() => {
      return state.selectList.map(item => item.name).join('、')
    })
    const resetList = (checked = false) => {
      state.list.forEach(item => (item.checked = checked))
    }
    // 初始化部门树
    // const initCurrentData = async () => {
    //   try {
    //     let result = {}
    //     let treeData = await store.state.departmentTreeData
    //     result.data = treeData.length > 0 ? treeData[0] : {}

    //     // const result = await getTreeDepartment()

    //     const { data } = result

    //     if (data && Object.keys(data).length > 0) {
    //       let topObj = JSON.parse(JSON.stringify(data))
    //       delete topObj.children
    //       topObj['type'] = 'department'

    //       state.topDepartment = topObj
    //     }
    //     state.list = (data?.childList || []).map(item => {
    //       item['type'] = 'department'
    //       return item
    //     })
    //     state.rootName = data?.name
    //     state.id = data?.id
    //     state.rootData = state.list
    //   } catch (error) {
    //     console.log('error: ', error)
    //   }
    // }
    /**
     * 搜索部门
     */
    const searchDepartment = async name => {
      transferDialog.value.setTransferSelectData('departmentLoading', true)
      try {
        let result = []
        if (!name) {
          const { data } = await getLazyTreeDepartment({
            parent_id:
              transferDialog.value.getTransferSelectData('navList')[
                transferDialog.value.getTransferSelectData.length - 1
              ]?.id,
            pageIndex: 1,
            pageSize: 20000
          })
          transferDialog.value.setTransferSelectData('departmentLoading', false)
          result = (data && data?.list) || []
        } else {
          const { data } = await getDepartmentList(null, name)
          result = (data && data?.list) || []
          transferDialog.value.setTransferSelectData('departmentLoading', false)
        }
        if (result.length > 0) {
          result.map(item => {
            item['type'] = 'department'
            return item
          })
        }
        transferDialog.value.setTransferSelectData('currentList', result)
        transferDialog.value.setTransferSelectData('navList', [
          transferDialog.value.getTransferSelectData('navList')[0]
        ])
      } catch (error) {
        transferDialog.value.setTransferSelectData('departmentLoading', false)
      }
    }
    // initCurrentData()
    // 监听选中数据，做回显操作
    const stop = watch(
      () => props.modelValue,
      value => {
        if (value && value.length) {
          getDepartmentList(props.modelValue, '', false, { pageNo: 1, pageSize: 5000 }).then(result => {
            const list = result.data?.list || []
            state.selectList = list.map(item => {
              item['type'] = 'department'
              return item
            })
            emit('ok', props.modelValue, state.selectList)
          })
        } else {
          state.selectList = []
          emit('ok', props.modelValue, state?.selectList ?? [])
        }
      },
      { immediate: true, deep: true }
    )
    // watch(
    //   () => state.visible,
    //   value => {
    //     if (value) {
    //       initCurrentData()
    //     }
    //   },
    //   { immediate: true, deep: true }
    // )

    const handleTrigger = () => {
      if (props.disabled) {
        return
      }
      state.config.show = true
      // state.visible = true
    }
    // 点击下级的时候的事件
    const nextFn = item => {
      if (!item.__changeType__) {
        state.list = item.childList.map(item => {
          item['type'] = 'department'
          return item
        })
        item.__changeType__ = true
      } else {
        state.list = item.childList
      }
    }
    const confirm = list => {
      state.selectList = list
      const ids = list.map(item => item.id)
      updateValue(ids)
      emit('ok', ids, list)
      emit('confirm', ids, list)
      emit('change', ids)
      // state.list = state.rootData
    }
    const cancel = () => {
      state.visible = false
      state.list = state.rootData
    }
    const getSelectedList = () => {
      return state.selectList
    }
    const triggerDialog = bool => {
      state.selectList = []
      resetList()
      state.visible = bool
    }
    const onSearch = text => {
      searchDepartment(text)
    }
    const cancelOrgan = () => {
      state.config.show = false
      state.list = state.rootData
    }
    const confirmOrgan = async list => {
      state.selectList = list
      const ids = list.map(item => item.id)
      state.config.show = false
      updateValue(ids)
      emit('ok', ids, list)
      emit('confirm', ids, list)
      emit('change', ids)
      state.list = state.rootData
    }
    onUnmounted(() => {
      typeof stop === 'function' && stop()
    })
    return {
      state,
      viewText,
      handleTrigger,
      confirm,
      cancel,
      nextFn,
      getSelectedList,
      triggerDialog,
      onSearch,
      transferDialog,
      cancelOrgan,
      confirmOrgan
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
