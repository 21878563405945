<template>
  <div class="page-header">
    <div class="back" @click="handleBackClick">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name: 'pageHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    back: {
      type: Function
    },
    isRouter: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter()
    const handleBackClick = () => {
      if (typeof props.back === 'function') {
        props.back()
      } else if (props.isRouter) {
        router.go(-1)
      }
    }
    return { handleBackClick }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
