<template>
  <!-- <teleport to="#layoutMain"> -->
    <div class="edit-app-role">
      <page-header :back="handleBack" title="操作权限设置"></page-header>
      <div class="container">
        <div class="module-card" v-for="promise in state.promiseList" :key="promise.id">
          <div class="card-header">
            <div class="text">{{ promise.name }}</div>
            <el-switch
              v-model="state.promiseKeys[promise.id].checked"
              @change="groupChange($event, promise)"
              active-color="#13ce66"
              inactive-color="#bbbbbb"
            />
          </div>
          <div
            class="sub-menu"
            :class="{ hide: !state.promiseKeys[promise.id].checked }"
            v-for="menu in promise.childList"
            :key="menu.id"
          >
            <div class="sub-menu-header">
              <el-checkbox
                v-model="state.promiseKeys[menu.id].checked"
                :disabled="state.promiseKeys[menu.id].disabled"
                @change="onSubChange($event, menu)"
              >
                <div class="text">{{ menu.name }}</div>
              </el-checkbox>
              <el-switch
                v-model="state.promiseKeys[menu.id].checked"
                :disabled="state.promiseKeys[menu.id].disabled"
                @change="onSubChange($event, menu)"
                active-color="#13ce66"
                inactive-color="#bbbbbb"
              />
            </div>
            <div
              class="sub-menu-body"
              :class="{ hide: !state.promiseKeys[menu.id].checked }"
              v-if="menu.childList && menu.childList.length"
            >
              <el-checkbox
                v-model="state.promiseKeys[action.id].checked"
                v-for="action in menu.childList"
                :label="action.id"
                :key="action.id"
                :disabled="state.promiseKeys[action.id].disabled || isViewAction(action)"
              >
                {{ action.name }}
              </el-checkbox>
            </div>
          </div>
          <!-- <div class="card-body"></div> -->
        </div>
        <div class="btn-save">
          <el-button type="primary" @click="onConfirm">保存</el-button>
        </div>
      </div>
    </div>
  <!-- </teleport> -->
</template>
<script>
import PageHeader from '@/views/components/page-header/index.vue'
import { getMenuPromisesTree } from '@/apis'
import { reactive } from 'vue'
export default {
  name: 'roleEdit',
  components: {
    PageHeader
  },
  props: {
    id: String,
    menus: {
      type: Array,
      default: () => []
    }
  },
  emits: ['cancel', 'confirm'],
  setup(props, { emit }) {
    const state = reactive({
      radio: '',
      promiseList: [],
      promiseKeys: {}
    })
    // 是否是查看按钮
    const isViewAction = action => {
      return action.permission.split(':').includes('view')
    }
    // 设置form的key
    const setPromiseForm = list => {
      list.forEach(item => {
        state.promiseKeys[item.id] = {
          checked: props.menus.includes(item.id),
          disabled: !props.menus.includes(item.parent_id)
        }
        if (Array.isArray(item.childList)) {
          setPromiseForm(item.childList)
        }
      })
    }
    const initPromiseData = async () => {
      const result = await getMenuPromisesTree(props.id)
      const list = result.data?.childList || []
      setPromiseForm(list)
      state.promiseList = list
    }
    initPromiseData()
    const handleBack = () => {
      emit('cancel')
    }
    // 二级改变的时候
    const onSubChange = (bool, menu, groupChange = false) => {
      // 如果是父级改变的时候
      if (groupChange) {
        state.promiseKeys[menu.id].checked = bool
        state.promiseKeys[menu.id].disabled = !bool
      }
      menu.childList.forEach(item => {
        state.promiseKeys[item.id].checked = bool
        state.promiseKeys[item.id].disabled = !bool
      })
    }
    // 上级改变的时候
    const groupChange = (bool, item) => {
      item.childList.forEach(menu => onSubChange(bool, menu, true))
    }
    const onConfirm = () => {
      const menuIds = []
      for (const key in state.promiseKeys) {
        if (state.promiseKeys[key].checked) {
          menuIds.push(key)
        }
      }
      menuIds.unshift(props.id)
      emit('confirm', { id: props.id, menuIds })
    }
    return { state, handleBack, onSubChange, groupChange, isViewAction, onConfirm }
  }
}
</script>
<style lang="scss" scoped>
@import './edit.scss';
</style>
