<template>
  <div class="edit-role-page" v-show="!state.showEdit">
    <div class="page-header">
      <i class="iconfont el-icon-arrow-left" @click="routerBack"></i>
      <div class="text">{{ id ? '编辑' : '添加' }}子管理员</div>
    </div>
    <div class="container">
      <el-form
        ref="roleFormRef"
        label-suffix="&nbsp;:"
        size="small"
        label-width="140px"
        label-position="right"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="管理组名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="成员" prop="employees">
          <user-select
            ref="userSelectComponent"
            nodeKey="userNo"
            placeholder="选择员工"
            @change="change"
            @delDialogSelectItem="delItemChange"
            @addItem="addItem"
            @cancel="cancel"
            @cancelCheckAll="cancelCheckAll"
            v-model="form.employees"
          ></user-select>
        </el-form-item>
        <!-- <el-form-item label="描述">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item> -->
        <el-form-item label="管理范围" prop="data_scope">
          <el-radio-group class="scope-radio" v-model="form.data_scope">
            <el-radio :label="CONTROL_SCOPE_DICT.ALL">{{ CONTROL_SCOPE_DICT[CONTROL_SCOPE_DICT.ALL] }}</el-radio>
            <el-radio :label="CONTROL_SCOPE_DICT.DEPARTMENT_ALL">
              {{ CONTROL_SCOPE_DICT[CONTROL_SCOPE_DICT.DEPARTMENT_ALL] }}
            </el-radio>
            <!-- <el-radio :label="CONTROL_SCOPE_DICT.DEPARTMENT">
              {{ CONTROL_SCOPE_DICT[CONTROL_SCOPE_DICT.DEPARTMENT] }}
            </el-radio> -->
          </el-radio-group>
          <!-- 特定部门 选择部门 -->
          <department-select
            v-if="form.data_scope === CONTROL_SCOPE_DICT.DEPARTMENT"
            v-model="form.department"
          ></department-select>
        </el-form-item>
        <el-form-item label="分配权限" class="dist-item">
          <div class="tip holder-text">(将会在以上管理范围配置权限)</div>
          <div class="checked-label">
            <div class="title">基础权限</div>
            <el-checkbox v-model="form.checkAll" @change="handleCheckAll">全部权限</el-checkbox>
          </div>
          <div class="app-wraps">
            <template v-for="app in appList" :key="app.id">
              <div class="app-item">
                <div class="app-icon">
                  <img v-if="app.id.startsWith('normal')" :src="app.icon" />
                  <img v-else :src="$getShowUrl(app.fileId)" alt="" />
                </div>
                <div class="app-name">{{ app.name }}</div>
                <div class="app-actions">
                  <el-radio-group :disabled="form.checkAll" v-model="form.apps[app.id].type">
                    <el-radio label="1">无</el-radio>
                    <el-radio label="2">全部</el-radio>
                    <el-radio label="3" v-if="!app.id.startsWith('normal')">细分</el-radio>
                  </el-radio-group>
                  <div class="edit">
                    <div class="text" v-if="form.apps[app.id].type === '3'" @click="editMenu(app)">修改</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer-btns">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>
  </div>
  <edit-promise
    :id="state.currentEditMenu.id"
    :menus="state.currentEditMenu.menus"
    v-if="state.showEdit"
    @confirm="onSelectConfirm"
    @cancel="state.showEdit = false"
  ></edit-promise>
</template>
<script>
import { reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  CONTROL_SCOPE_DICT,
  ICON_ADDRESS_PROMISS,
  ICON_COMPANY_PROMISS,
  ICON_HOME_PROMISS,
  ICON_SETTING_PROMISS
} from '@/common/const'
import { useApplication } from '@/hooks/apps'
import { computed, nextTick } from '@vue/runtime-core'
import { addRole, getRole, updateRole } from '@/apis'
import EditPromise from './edit.vue'
import { ElMessage } from 'element-plus'
import UserSelect from '@/components/user-select'
import DepartmentSelect from '@/components/department-select'
import { locateToErr } from '@/common/utils'
import { ElLoading } from 'element-plus'
export default {
  name: 'editRole',
  components: { EditPromise, UserSelect, DepartmentSelect },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const id = route.params.id
    const roleFormRef = ref(null)
    const userSelectComponent = ref(null)
    const { state: appState } = useApplication(true)
    const state = reactive({
      accountList: [],
      showEdit: false,
      currentEditMenu: {},
      currentData: {},
      delList: [], //被删除的选项
      addList: [] //左边新增的选项
    })
    // 默认的权限
    const normalPromisee = [
      { id: 'normal_frontPageState', key: 'frontPageState', icon: ICON_HOME_PROMISS, name: '首页', type: '1' },
      { id: 'normal_newsletterState', key: 'newsletterState', icon: ICON_ADDRESS_PROMISS, name: '通讯录', type: '1' },
      { id: 'normal_businessState', key: 'businessState', icon: ICON_COMPANY_PROMISS, name: '我的企业', type: '1' },
      { id: 'normal_settingState', key: 'settingState', icon: ICON_SETTING_PROMISS, name: '设置', type: '1' }
    ]
    // 表单
    const form = reactive({
      remarks: '',
      employees: [],
      department: [],
      data_scope: CONTROL_SCOPE_DICT.DEPARTMENT_ALL,
      checkAll: false,
      apps: {},
      roleInfo: {}
    })
    const employeeValidator = (rule, value, callback) => {
      if (value && value.length) return callback()
      return callback(rule.message)
    }
    const rules = {
      name: [{ required: true, message: '请输入管理组名称', trigger: 'blur' }],
      employees: [{ required: true, validator: employeeValidator, message: '请选择成员', trigger: 'blur' }],
      data_scope: [{ required: true, message: '请选择管理范围', trigger: 'blur' }]
    }
    normalPromisee.forEach(item => {
      form.apps[item.id] = {
        id: item.id,
        key: item.key,
        type: '1'
      }
    })
    const initRoleInfo = () => {
      getRole(id).then(res => {
        const { data } = res
        state.currentData = data || {}
        if (data) {
          state.roleInfo = res.data
          form.name = data.name
          form.data_scope = data.data_scope
          form.remarks = data.remarks
          form.department = data.manageDepartment || []
          data.roleItems.forEach(item => {
            if (form.apps[item.menuId]) {
              form.apps[item.menuId].id = item.menuId
              form.apps[item.menuId].type = item.type
              form.apps[item.menuId].menus = item.menus
            } else {
              form.apps[item.menuId] = {
                id: item.menuId,
                type: item.type,
                menus: item.menus
              }
            }
          })
          normalPromisee.forEach(item => {
            form.apps[item.id].type = data[item.key] ? '2' : '1'
          })
          nextTick(() => {
            userSelectComponent.value.initRoleUserData()
          })
        }
      })
    }
    // use
    const change = data => {
      console.log('data: ', data)
      roleFormRef.value && roleFormRef.value.validateField('employees')
    }
    const delItemChange = arr => {
      console.log('delItem-----------data: ', arr)
      console.log('form.employees-----------data: ', form.employees)
      state.delList.push(...arr)
      // if (userSelectComponent.value) {
      //   let lang = userSelectComponent.value.getSelectLength()
      //   if (lang.length === 0) {
      //     //删除最后一个元素时请求下一页数据
      //     userSelectComponent.value.loadingPage()
      //   }
      // }
    }
    const cancel = () => {
      //取消选择
      state.delList = []
    }
    const cancelCheckAll = data => {
      console.log('cancelCheckAll------------------data: ', data)
      //取消选择
      state.delList = state.delList.concat(data)
    }
    // 如果存在Id 则获取信息
    if (id) {
      initRoleInfo()
    }
    // 设置的菜单
    const SETTING_ID = '04d32d1188cc467eb7a6f363bb23760a'
    const appList = computed(() => {
      const list = appState.appList.reduce((list, item) => list.concat(item.apps.filter(app => app.show)), [])
      list.forEach(item => {
        if (!form.apps[item.id]) {
          form.apps[item.id] = {
            id: item.id,
            type: '1',
            menus: []
          }
        } else {
          form.apps[item.id].id = item.id
        }
      })
      return normalPromisee.concat(list)
    })
    const hanleRequest = async form => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading'
      })
      try {
        const normal = {}
        Object.values(form.apps)
          .slice(0, 4)
          .forEach(item => {
            normal[item.key] = +item.type === 2 ? true : false
          })
        // 前四项为默认的 需要特殊处理
        const roleItems = Object.values(form.apps)
          .slice(4)
          .map(item => ({
            menuId: item.id,
            type: item.type,
            menus: item.menus
          }))
        const params = {
          name: form.name,
          data_scope: form.data_scope,
          remarks: form.remarks,
          employeeIds: form.employees,
          roleItem: roleItems,
          ...normal
        }
        if (form.data_scope === CONTROL_SCOPE_DICT.DEPARTMENT) {
          params['manageDepartment'] = form.department
        }
        if (id) {
          params['id'] = id
          params['newly'] = [...new Set(state.addList)]
          params['removed'] = (state.delList || []).map(item => item.userNo)
          updateRole(params).then(res => {
            loading.close()
            if (res.isOk) {
              ElMessage.success('编辑子管理员成功')
              router.go(-1)
            }
          })
        } else {
          addRole(params).then(res => {
            loading.close()
            if (res.isOk) {
              router.go(-1)
              ElMessage.success('新增子管理员成功')
            }
          })
        }
      } catch (error) {
        loading.close()
        ElMessage.error(id ? '编辑失败' : '新增失败')
      }
    }
    const onSubmit = () => {
      roleFormRef.value.validate(valid => {
        if (valid) {
          hanleRequest(form)
        } else {
          locateToErr()
        }
      })
    }
    const editMenu = app => {
      state.currentEditMenu = app
      state.currentEditMenu.menus = form.apps[app.id].menus || state.currentEditMenu.menus || []
      state.showEdit = true
    }
    const onSelectConfirm = ({ id, menuIds }) => {
      state.showEdit = false
      form.apps[id].menus = menuIds
      console.log('form.apps[id]', form.apps[id])
    }
    const handleCheckAll = bool => {
      const type = bool ? '2' : '1'
      for (const key in form.apps) {
        form.apps[key].type = type
      }
    }
    const addItem = arr => {
      console.log('addItem---data: ', arr)
      state.addList.push(...arr)
    }
    const routerBack = () => {
      router.go(-1)
    }

    return {
      id,
      roleFormRef,
      form,
      rules,
      change,
      CONTROL_SCOPE_DICT,
      state,
      appList,
      onSubmit,
      editMenu,
      routerBack,
      handleCheckAll,
      onSelectConfirm,
      SETTING_ID,
      userSelectComponent,
      delItemChange,
      cancel,
      cancelCheckAll,
      addItem
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
